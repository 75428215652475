/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Configurar un timeout global de 10 segundos (10000 milisegundos)
window.axios.defaults.timeout = 10000; // 10 segundos
// Interceptor para capturar respuestas con código de estado 401
window.axios.interceptors.response.use(
    (response) => {
      // Si la respuesta es exitosa, simplemente devuélvela para que sea manejada normalmente.
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        // Si la respuesta tiene un código de estado 401, significa que el usuario no está autenticado.
        // Aquí redirigiremos al usuario a la página de inicio de sesión.
  
        // Redirigir al usuario a la página de inicio de sesión (reemplaza "/login" con la ruta real)
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
);

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
//     wsHost: import.meta.env.VITE_PUSHER_HOST ? import.meta.env.VITE_PUSHER_HOST : `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });
